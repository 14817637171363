import urlParse from 'url-parse'
import { LEGACY_BASE_URL } from '@/config'
import { Route } from 'vue-router'

export const urlTransformer = (route: Route) => {
  const siteId = route && route.params && route.params.siteId ? route.params.siteId : ''
  const baseUrl = LEGACY_BASE_URL.replace('{siteId}', siteId)
  const legacyUrlWithoutParams = route && route.params && route.params.legacyUrl ? route.params.legacyUrl : ''
  const parsedUrl = urlParse(legacyUrlWithoutParams, baseUrl)
  let query = route.fullPath.split('?', 2)[1] || ''
  if (query.length > 0) {
    query = '?' + query
  }
  return parsedUrl.href + query
}
