







import { LEGACY_BASE_URL } from '@/config'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { vxm } from '@/store'
import urlParse from 'url-parse'
import { urlTransformer } from '@/utils/legacyUtils'

@Component
export default class VueFrame extends Vue {
  $refs!: {
    iframe: HTMLIFrameElement
  }

  initialUrl = ''

  @Prop({ type: String }) url

  get frameUrl() {
    return urlTransformer(this.$route)
  }

  mounted() {
    window.addEventListener('message', this.receiveMessage)
    window.addEventListener('popstate', window.onpopstate)
    if (this.url) {
      this.setIframeUrl(LEGACY_BASE_URL.replace('{siteId}', this.$route.params.siteId) + this.url)
    } else {
      this.onRouteChange()
    }
  }

  destroyed() {
    window.removeEventListener('message', this.receiveMessage)
    window.removeEventListener('popstate', window.onpopstate)
    if (this.$store.commit) {
      this.$store.commit('setLoading', false)
    }
  }

  @Watch('$route')
  onRouteChange() {
    this.setTitle(this.$route.params.legacyUrl)
    this.setIframeUrl(this.frameUrl)
  }

  setIframeUrl(url) {
    if (this.$store.commit) {
      this.$store.commit('setLoading', true)
    }
    this.$refs.iframe.contentWindow.location.replace(url)
  }

  setTitle(title) {
    document.title = title
  }

  sendLoginWithToken() {
    const token = vxm.user.token
    let appDomain = document.location.protocol + '//' + document.location.hostname
    if (document.location.port) {
      appDomain += ':' + document.location.port
    }
    this.$refs.iframe.contentWindow.postMessage(
      {
        type: 'LoginWithToken',
        token,
        appDomain,
      },
      '*',
    )
    vxm.user.setLegacyFrameToken(token)
  }

  trySendLoginWithToken() {
    if (!vxm.user.legacyFrameToken) {
      // After page is loaded, check if we've authenticated legacy-frame since initial app load,
      // and if not request it to (re)authenticate. This to handle possible case where oX.eontyre.com
      // is logged in, but not as the user that we're logged into app.eontyre.com as.
      this.sendLoginWithToken()
      return true
    }
    return false
  }

  async receiveMessage(e) {
    if (!e.data) {
      return
    }
    if (e.data['gaello-redirect']) {
      const parsedUrl = urlParse(e.data['gaello-redirect'])
      parsedUrl.set('pathname', '/' + this.$route.params.siteId + parsedUrl.pathname)
      const parsedUrlWithQuery = parsedUrl.pathname + parsedUrl.query
      const currentUrlWithQuery = this.$route.fullPath
      if (parsedUrlWithQuery !== currentUrlWithQuery) {
        this.$router.push(parsedUrlWithQuery)
      }
      return
    }
    switch (e.data.type) {
      case 'LegacyReAuthenticate':
        // No user is logged in inside the iframe, ask it to authenticate with our current token
        this.sendLoginWithToken()
        break
      case 'LegacyFailedAuthentication':
        // We asked it to authenticate but it failed, do a full logout and let user try again
        vxm.user.setLegacyFrameToken('')
        this.$store.dispatch('logout').then(() => {
          this.$router.push('/login')
        })
        break
      case 'LegacyFrameNavigate':
        // Iframe changed url (link, form, redirect, etc), so update our actual browser url,
        // which will in turn cause the iframe to actually navigate to its version of that url.
        //
        // Here too we must trySendLoginWithToken() because a redirect may be our first encounter with the iframe
        // (rather than a page-load, LegacyFrameLoad). And if we're not currently sure we're logged in as the
        // right user, we must handle that before proceeding with redirects.
        // So we call trySendLoginWithToken(), and if it has a need to re-auth, we do that instead of following
        // the redir. That will in turn cause a page reload inside the iframe, which will cause a new
        // LegacyFrameLoad or LegacyFrameNavigate, which will then be authenticated, hence we're good to proceed (phew!).
        if (!this.trySendLoginWithToken()) {
          const parsedUrl = urlParse(e.data.url)
          parsedUrl.set('pathname', '/' + this.$route.params.siteId + parsedUrl.pathname)
          const parsedUrlWithQuery = parsedUrl.pathname + parsedUrl.query
          const currentUrlWithQuery = this.$route.fullPath
          if (parsedUrlWithQuery !== currentUrlWithQuery) {
            this.$router.push(parsedUrlWithQuery)
          }
        }
        break
      case 'LegacyFrameNavigateAbsolute':
        // As above, but jump out of our app and navigate to an absolute url (typically external)
        document.location.href = e.data.url
        break

      case 'LegacyFrameLoad':
        // Indicates that iframe has finished loading a new url
        if (this.$store.commit) {
          this.$store.commit('setLoading', false)
        }
        this.setTitle(e.data.title)
        // In LegacyReAuthenticate we login the iframe IFF it is not logged in already.
        // We could however be logged in with another user than the current one,
        // so to handle this we force a login when app.eontyre.com loads.
        // The below function handles doing this on the first iframe page load.
        this.trySendLoginWithToken()
        break

      case 'LegacyFrameLogin': {
        // If form some reason we did a login inside the iframe, this occurs
        // Not sure if it works / how it is supposed to work, but don't dare to remove it per now.
        const parts = document.location.href.split('/')
        const url = parts[0] + '//' + parts[2]
        if (url === document.location.href) {
          document.location.reload()
        } else {
          document.location.href = url
        }
        break
      }
    }
  }
}
